<template>
  <div class="space my6 flex flex-col gap-8 md:my0">
    <div>
      <h3 class="text-base fw600 uppercase md:block n-text-heading">
        Přepravní společnosti
      </h3>
      <div class="mt2 max-w-sm rounded-md rounded-md bg-gray-1 px1 py2">
        <NuxtImg src="/footer/couriers.png" alt="Couriers" />
      </div>
    </div>
    <div>
      <h3 class="text-base fw600 uppercase md:block n-text-heading">
        Platební metody
      </h3>
      <div class="mt2 max-w-sm rounded-md rounded-md bg-gray-1 px1 py2">
        <NuxtImg src="/footer/payment_methods.png" alt="Payments" />
      </div>
    </div>
    <div>
      <h3 class="text-base fw600 uppercase md:block n-text-heading">
        Bezpečnostní certifikace
      </h3>
      <div class="mt2 max-w-sm rounded-md rounded-md bg-gray-1 px1 py2">
        <NuxtImg src="/footer/certificates.png" alt="Certificates" />
      </div>
    </div>
  </div>
</template>
